import mock from './mock';

mock.onGet('/flightinfo/v1/flights/airport/:airportCode/direction/:directionD').reply((config) => {
    console.log(config)
    // console.log(airportCode)
    // console.log(direction)

    try {
        const response = {
            "flightRecords": [
                {
                    "flightIdentifier": {
                        "operatingCarrier": {
                            "iataCode": "KL",
                            "flightNumber": "1146",
                            "name": "KLM Royal Dutch Airlines (D)"
                        },
                        "marketingCarriers": [
                            {
                                "iataCode": "DL",
                                "flightNumber": "9598",
                                "name": "Delta Air Lines"
                            }
                        ],
                        "aircraft": {
                            "iataCode": "EMJ",
                            "registration": "PHEZH"
                        }
                    },
                    "departure": {
                        "airport": {
                            "iataCode": "OSL",
                            "cityText": "Oslo"
                        },
                        "scheduled": "2020-01-22T12:00:00+0100",
                        "status": "SC",
                        "statusText": "Scheduled",
                        "terminal": "2",
                    },
                    "arrival": {
                        "airport": {
                            "iataCode": "AMS",
                            "cityText": "Amsterdam"
                        },
                        "scheduled": "2020-01-22T16:00:00+0100",
                        "estimated": "2020-01-22T16:03:00+0100",
                        "actual": "2020-01-22T16:03:00+0100",
                        "status": "AR",
                        "statusText": "Arrived",
                        "terminal": "2",
                        "gate": "A4",
                        "carousel": "9",
                        "gateSlots": [
                            {
                                "externalName": "A4"
                            }
                        ]
                    },
                    "serviceType": "J",
                    "duration": "240",
                },
                {
                    "flightIdentifier": {
                        "operatingCarrier": {
                            "iataCode": "KL",
                            "flightNumber": "1386",
                            "name": "KLM Royal Dutch Airlines"
                        },
                        "aircraft": {
                            "iataCode": "73H",
                            "registration": "PHHSE"
                        }
                    },
                    "departure": {
                        "airport": {
                            "iataCode": "KBP",
                            "cityText": "Kiev"
                        },
                        "scheduled": "2020-01-22T12:00:00+0200",
                        "status": "SC",
                        "statusText": "Scheduled",
                        "terminal": "3",
                    },
                    "arrival": {
                        "airport": {
                            "iataCode": "AMS",
                            "cityText": "Amsterdam"
                        },
                        "scheduled": "2020-01-22T16:00:00+0100",
                        "estimated": "2020-01-22T15:44:00+0100",
                        "actual": "2020-01-22T15:44:00+0100",
                        "status": "AR",
                        "statusText": "Arrived",
                        "terminal": "3",
                        "gate": "A9",
                        "carousel": "17",
                        "gateSlots": [
                            {
                                "externalName": "A9"
                            }
                        ]
                    },
                    "serviceType": "J",
                    "duration": "300",
                },
                {
                    "flightIdentifier": {
                        "operatingCarrier": {
                            "iataCode": "KL",
                            "flightNumber": "1394",
                            "name": "KLM Royal Dutch Airlines"
                        },
                        "aircraft": {
                            "iataCode": "73H",
                            "registration": "PHBXG"
                        }
                    },
                    "departure": {
                        "airport": {
                            "iataCode": "LED",
                            "cityText": "St. Petersburg"
                        },
                        "scheduled": "2020-01-22T12:00:00+0300",
                        "status": "SC",
                        "statusText": "Scheduled",
                        "terminal": "3",
                        "delayPrediction": {
                            "message": "Prediction data not available"
                        }
                    },
                    "arrival": {
                        "airport": {
                            "iataCode": "AMS",
                            "cityText": "Amsterdam"
                        },
                        "scheduled": "2020-01-22T16:00:00+0100",
                        "estimated": "2020-01-22T15:47:00+0100",
                        "actual": "2020-01-22T15:47:00+0100",
                        "status": "AR",
                        "statusText": "Arrived",
                        "terminal": "3",
                        "gate": "A9",
                        "carousel": "15",
                        "gateSlots": [
                            {
                                "externalName": "A9"
                            }
                        ],
                        "delayPrediction": {
                            "confidence": 0.7670605354,
                            "minutes": 235,
                            "prediction": "2020-01-22T15:47:00+0100"
                        }
                    },
                    "serviceType": "J",
                    "duration": "360",
                }
            ]
        }

        return [200, {
            data: response
        }];
    } catch (error) {
        console.error(error);
        return [500, { message: 'Internal server error' }];
    }
});

mock.onGet('/flightinfo/v1/flights/airport/:airportCode/direction/:directionA').reply((config) => {
    console.log(config)
    // console.log(airportCode)
    // console.log(direction)

    try {
        const response = {
            "flightRecords": [
                {
                    "flightIdentifier": {
                        "operatingCarrier": {
                            "iataCode": "KL",
                            "flightNumber": "1146",
                            "name": "KLM Royal Dutch Airlines (A)"
                        },
                        "marketingCarriers": [
                            {
                                "iataCode": "DL",
                                "flightNumber": "9598",
                                "name": "Delta Air Lines"
                            }
                        ],
                        "aircraft": {
                            "iataCode": "EMJ",
                            "registration": "PHEZH"
                        }
                    },
                    "departure": {
                        "airport": {
                            "iataCode": "OSL",
                            "cityText": "Oslo"
                        },
                        "scheduled": "2020-01-22T12:00:00+0100",
                        "status": "SC",
                        "statusText": "Scheduled",
                        "terminal": "2",
                    },
                    "arrival": {
                        "airport": {
                            "iataCode": "AMS",
                            "cityText": "Amsterdam"
                        },
                        "scheduled": "2020-01-22T16:00:00+0100",
                        "estimated": "2020-01-22T16:03:00+0100",
                        "actual": "2020-01-22T16:03:00+0100",
                        "status": "AR",
                        "statusText": "Arrived",
                        "terminal": "2",
                        "gate": "A4",
                        "carousel": "9",
                        "gateSlots": [
                            {
                                "externalName": "A4"
                            }
                        ]
                    },
                    "serviceType": "J",
                    "duration": "240",
                },
                {
                    "flightIdentifier": {
                        "operatingCarrier": {
                            "iataCode": "KL",
                            "flightNumber": "1386",
                            "name": "KLM Royal Dutch Airlines"
                        },
                        "aircraft": {
                            "iataCode": "73H",
                            "registration": "PHHSE"
                        }
                    },
                    "departure": {
                        "airport": {
                            "iataCode": "KBP",
                            "cityText": "Kiev"
                        },
                        "scheduled": "2020-01-22T12:00:00+0200",
                        "status": "SC",
                        "statusText": "Scheduled",
                        "terminal": "3",
                    },
                    "arrival": {
                        "airport": {
                            "iataCode": "AMS",
                            "cityText": "Amsterdam"
                        },
                        "scheduled": "2020-01-22T16:00:00+0100",
                        "estimated": "2020-01-22T15:44:00+0100",
                        "actual": "2020-01-22T15:44:00+0100",
                        "status": "AR",
                        "statusText": "Arrived",
                        "terminal": "3",
                        "gate": "A9",
                        "carousel": "17",
                        "gateSlots": [
                            {
                                "externalName": "A9"
                            }
                        ]
                    },
                    "serviceType": "J",
                    "duration": "300",
                },
                {
                    "flightIdentifier": {
                        "operatingCarrier": {
                            "iataCode": "KL",
                            "flightNumber": "1394",
                            "name": "KLM Royal Dutch Airlines"
                        },
                        "aircraft": {
                            "iataCode": "73H",
                            "registration": "PHBXG"
                        }
                    },
                    "departure": {
                        "airport": {
                            "iataCode": "LED",
                            "cityText": "St. Petersburg"
                        },
                        "scheduled": "2020-01-22T12:00:00+0300",
                        "status": "SC",
                        "statusText": "Scheduled",
                        "terminal": "3",
                        "delayPrediction": {
                            "message": "Prediction data not available"
                        }
                    },
                    "arrival": {
                        "airport": {
                            "iataCode": "AMS",
                            "cityText": "Amsterdam"
                        },
                        "scheduled": "2020-01-22T16:00:00+0100",
                        "estimated": "2020-01-22T15:47:00+0100",
                        "actual": "2020-01-22T15:47:00+0100",
                        "status": "AR",
                        "statusText": "Arrived",
                        "terminal": "3",
                        "gate": "A9",
                        "carousel": "15",
                        "gateSlots": [
                            {
                                "externalName": "A9"
                            }
                        ],
                        "delayPrediction": {
                            "confidence": 0.7670605354,
                            "minutes": 235,
                            "prediction": "2020-01-22T15:47:00+0100"
                        }
                    },
                    "serviceType": "J",
                    "duration": "360",
                }
            ]
        }

        return [200, {
            data: response
        }];
    } catch (error) {
        console.error(error);
        return [500, { message: 'Internal server error' }];
    }
});