const hostname = window.location.hostname;

// TODO: change on deploy
export const backendServerBaseURL = hostname.includes("localhost")
  ? "http://localhost:7400/api/v1"
  : "https://airport-backend.pocsample.in/api/v1";
export const staticBaseURL = hostname.includes("localhost")
  ? "http://localhost:7400"
  : "https://airport-backend.pocsample.in";
export const socketURL = hostname.includes("localhost")
  ? "http://localhost:7400"
  : "https://airport-backend.pocsample.in";
