import Router from "./routes";
import ThemeConfig from "./theme";
import socketHelper from './utils/socketHelper';
import { useEffect } from 'react';

let socket = new socketHelper().socket;

socket.on("me", (id) => {
  console.log('Me - ', id);
  localStorage.setItem('mySocketId', id)
});

socket.on("connect", () => {
  console.log('Socket connected')
});

socket.on("disconnect", () => {
  console.log('Socket disconnected')
});

socket.on("forceDisconnect", () => {
  console.log('Socket forcefully disconnected by the server')
  localStorage.clear();
  window.open('/login', '_self')
});

socket.on("forceKioskDataUpdate", (payload) => {
  console.log('Kiosk data updated due to manager action')
  localStorage.setItem('kiosk', JSON.stringify(payload))
});

function App() {
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement({
      pageLanguage: 'en',
      layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT
    }, 'google_translate_element')
  }

  useEffect(() => {
    var addScript = document.createElement('script');
    addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, [])

  return (
    <>
      <ThemeConfig>
        <div id="google_translate_element"></div>
        <Router socket={socket} />
      </ThemeConfig>
    </>
  );
}

export default App;
