import { Box, Rating, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../../components/MainNav/Header";
import { submitCallFeedback } from "../../redux/slices/helpDeskSlice";

export default function HelpDeskFeedback() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Box sx={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <Header />

      <Box sx={{ minHeight: "100%", backgroundColor: "#4F339D", flexGrow: 1 }}>
        <Box p={3}>
          <Box
            p={4}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h1" color="black" textAlign="center">
              How was your experience?
            </Typography>

            <Box mt={5}>
              <Rating
                name="size-large"
                defaultValue={0}
                size="large"
                sx={{ fontSize: 100 }}
                onChange={(e, value) => {
                  dispatch(submitCallFeedback({ stars: value, navigate }));
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
