import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendServerBaseURL } from '../../utils/backendServerBaseURL';

const initialState = {
  faqs: [],
  faqsCategories: [],
  selectedFAQ: null
};

export const loadFAQs = createAsyncThunk(
  'faq/loadFAQs',
  async (payload, thunkAPI) => {
    const terminalData = JSON.parse(localStorage.getItem("terminal", ""))

    let config = {
      params: {
        id: terminalData._id,
        categoryId: payload.categoryId
      }
    }

    let response = await axios.get(`${backendServerBaseURL}/kiosk/faq/questions/read`, config);

    if (response.status === 200) {
      thunkAPI.dispatch(updateFaqs(response.data.payload))
    }
  }
);

export const loadFAQsCategories = createAsyncThunk(
  'faq/loadFAQsCategories',
  async (_, thunkAPI) => {
    const terminalData = JSON.parse(localStorage.getItem("terminal", ""))

    let config = {
      params: {
        id: terminalData._id
      }
    }

    let response = await axios.get(`${backendServerBaseURL}/kiosk/faq/categories/read`, config);

    if (response.status === 200) {
      thunkAPI.dispatch(updateFaqsCategories(response.data.payload))
    }
  }
);

export const faqSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    updateFaqs: (state, action) => {
      state.faqs = action.payload;
    },
    updateFaqsCategories: (state, action) => {
      state.faqsCategories = action.payload;
    },
    updateSelectedFAQ: (state, action) => {
      state.selectedFAQ = action.payload;
    },
  }
})

export const {
  updateFaqs,
  updateFaqsCategories,
  updateSelectedFAQ
} = faqSlice.actions;

export const selectFaqs = (state) => state.faq.faqs;
export const selectFaqsCategories = (state) => state.faq.faqsCategories;
export const selectSelectedFAQ = (state) => state.faq.selectedFAQ;


export default faqSlice.reducer;
