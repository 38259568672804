import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/MainNav/Header';

export default function Departure() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()

    return (
      <Box
        sx={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
      >
        <Header />

        <Box
          sx={{ minHeight: "100%", backgroundColor: "#ffffff96", flexGrow: 1 }}
        >
          <Box p={3}>
            <Container>
              <Box pt={3} pb={3}>
                <Typography variant="h3" color="black">
                  Deprture
                </Typography>
              </Box>

              <Grid container spacing={2}>
                <Grid
                  item
                  xs={4}
                  onClick={() => {
                    navigate(`/flights/${params.direction}`);
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      height: "10rem",
                      width: "100%",
                    }}
                    alt="Flights"
                    src="/static/departure/flights.png"
                  ></Box>

                  <Box
                    p={2}
                    sx={{
                      position: "relative",
                      top: -25,
                      backgroundColor: "black",
                      opacity: 0.7,
                      minWidth: "100%",
                    }}
                  >
                    <Typography variant="h4" textAlign={"center"}>
                      Flights
                    </Typography>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={4}
                  onClick={() => {
                    navigate(`/gates/${params.direction}`);
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      height: "10rem",
                      width: "100%",
                    }}
                    alt="Gates"
                    src="/static/departure/gates.png"
                  ></Box>

                  <Box
                    p={2}
                    sx={{
                      position: "relative",
                      top: -25,
                      backgroundColor: "black",
                      opacity: 0.7,
                      minWidth: "100%",
                    }}
                  >
                    <Typography variant="h4" textAlign={"center"}>
                      Gates
                    </Typography>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={4}
                  onClick={() => {
                    navigate(`/airlines/${params.direction}`);
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      height: "10rem",
                      width: "100%",
                    }}
                    alt="Airlines"
                    src="/static/departure/airlines.png"
                  ></Box>

                  <Box
                    p={2}
                    sx={{
                      position: "relative",
                      top: -25,
                      backgroundColor: "black",
                      opacity: 0.7,
                      minWidth: "100%",
                    }}
                  >
                    <Typography variant="h4" textAlign={"center"}>
                      Airlines
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
      </Box>
    );
}
