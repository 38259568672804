import React, { useEffect } from 'react'
import { Box, Grid, Typography, Card, Container, Stack } from '@mui/material'
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { Avatar } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import { useNavigate } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { useDispatch, useSelector } from 'react-redux';
import { loadFeedbackQuestions, selectFeedbackQuestions, selectCurrentFeedbackQuestionIndex, submitAnswer, updateCurrentFeedbackQuestionIndex } from '../redux/slices/feedbackSlice';
import { useSelect } from '@mui/base';
import { Button } from '@mui/material';
import { useRef } from 'react';
import { useState } from 'react';
import ChatbotHeader from '../components/Chatbot/ChatbotHeader';

export default function Feedback() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const feedbackQuestions = useSelector(selectFeedbackQuestions)
    const currentFeedbackQuestionIndex = useSelector(selectCurrentFeedbackQuestionIndex)
    const [answer, setanswer] = useState('')

    useEffect(() => {
        dispatch(loadFeedbackQuestions())
    }, [])

    useEffect(() => {
        if (feedbackQuestions.length !== 0 && feedbackQuestions.length - 1 < currentFeedbackQuestionIndex) {
            dispatch(updateCurrentFeedbackQuestionIndex(0))
            navigate('/thank-you-feedback')
        }
    }, [currentFeedbackQuestionIndex])

    return (
        <>
            <ChatbotHeader />

            {
                feedbackQuestions.length && feedbackQuestions.length - 1 >= currentFeedbackQuestionIndex && <Stack p={4} spacing={3}>
                    <Typography
                        variant='h3'
                    >
                        Q{currentFeedbackQuestionIndex + 1}) {feedbackQuestions[currentFeedbackQuestionIndex].question}
                    </Typography>

                    <FormControl>
                        <RadioGroup
                            defaultValue={answer}
                            value={answer}
                            onChange={(e, v) => { setanswer(v) }}
                            name="radio-buttons-group"
                        >
                            {
                                feedbackQuestions[currentFeedbackQuestionIndex].options.map(option => {
                                    return <FormControlLabel
                                        key={option}
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                                fontSize: 24,
                                            },
                                        }}
                                        value={option}
                                        control={<Radio sx={{
                                            '& .MuiSvgIcon-root': {
                                                fontSize: 32,
                                            },
                                        }}
                                        />}
                                        label={option} />

                                })
                            }
                        </RadioGroup>
                    </FormControl>

                    <Button variant='contained' size='large' onClick={() => {
                        dispatch(submitAnswer({
                            question: feedbackQuestions[currentFeedbackQuestionIndex].question,
                            answer: answer
                        }))
                    }}>Next</Button>
                </Stack>
            }


        </>
    )
}
