import { MenuItem, TextField } from '@mui/material';
import { withStyles } from '@mui/styles';
import React, { useState } from 'react';


const CssTextField = withStyles({
    root: {
        '& .MuiInputBase-root': {
            color: '#121212',
        },
    },
})(TextField);

export default function LanguageSelector() {
    const [language, setlanguage] = useState('en')

    return (
        <CssTextField
            select
            fullWidth
            value={language}
            className="notranslate"
            onChange={(e) => {
                console.log(e.target.value);
                if (e.target.value !== null && e.target.value !== undefined) {
                    setlanguage(e.target.value)
                    window.changeLanguage(e.target.value)
                }
            }}
            sx={{ backgroundColor: 'white', marginBottom: 2, borderRadius: 1 }}
        >
            <MenuItem value="en" sx={{ color: '#121212' }} className="notranslate">English</MenuItem>
            <MenuItem value="hi" sx={{ color: '#121212' }} className="notranslate">Hindi</MenuItem>
            <MenuItem value="mr" sx={{ color: '#121212' }} className="notranslate">Marathi</MenuItem>
            <MenuItem value="gu" sx={{ color: '#121212' }} className="notranslate">Gujarati</MenuItem>
            <MenuItem value="te" sx={{ color: '#121212' }} className="notranslate">Telugu</MenuItem>
            <MenuItem value="pa" sx={{ color: '#121212' }} className="notranslate">Punjabi</MenuItem>
            <MenuItem value="kn" sx={{ color: '#121212' }} className="notranslate">Kannada</MenuItem>
            <MenuItem value="ml" sx={{ color: '#121212' }} className="notranslate">Malayalam</MenuItem>



            {/* <MenuItem value="af" sx={{ color: '#121212' }}>Afrikaans</MenuItem>
            <MenuItem value="sq" sx={{ color: '#121212' }}>Albanian</MenuItem>
            <MenuItem value="am" sx={{ color: '#121212' }}>Amharic</MenuItem>
            <MenuItem value="ar" sx={{ color: '#121212' }}>Arabic</MenuItem>
            <MenuItem value="hy" sx={{ color: '#121212' }}>Armenian</MenuItem>
            <MenuItem value="az" sx={{ color: '#121212' }}>Azerbaijani</MenuItem>
            <MenuItem value="eu" sx={{ color: '#121212' }}>Basque</MenuItem>
            <MenuItem value="be" sx={{ color: '#121212' }}>Belarusian</MenuItem>
            <MenuItem value="bn" sx={{ color: '#121212' }}>Bengali</MenuItem>
            <MenuItem value="bs" sx={{ color: '#121212' }}>Bosnian</MenuItem>
            <MenuItem value="bg" sx={{ color: '#121212' }}>Bulgarian</MenuItem>
            <MenuItem value="ca" sx={{ color: '#121212' }}>Catalan</MenuItem>
            <MenuItem value="ceb" sx={{ color: '#121212' }}>Cebuano</MenuItem>
            <MenuItem value="ny" sx={{ color: '#121212' }}>Chichewa</MenuItem>
            <MenuItem value="zh-CN" sx={{ color: '#121212' }}>Chinese (Simplified)</MenuItem>
            <MenuItem value="zh-TW" sx={{ color: '#121212' }}>Chinese (Traditional)</MenuItem>
            <MenuItem value="co" sx={{ color: '#121212' }}>Corsican</MenuItem>
            <MenuItem value="hr" sx={{ color: '#121212' }}>Croatian</MenuItem>
            <MenuItem value="cs" sx={{ color: '#121212' }}>Czech</MenuItem>
            <MenuItem value="da" sx={{ color: '#121212' }}>Danish</MenuItem>
            <MenuItem value="nl" sx={{ color: '#121212' }}>Dutch</MenuItem>

            <MenuItem value="eo" sx={{ color: '#121212' }}>Esperanto</MenuItem>
            <MenuItem value="et" sx={{ color: '#121212' }}>Estonian</MenuItem>
            <MenuItem value="tl" sx={{ color: '#121212' }}>Filipino</MenuItem>
            <MenuItem value="fi" sx={{ color: '#121212' }}>Finnish</MenuItem>
            <MenuItem value="fr" sx={{ color: '#121212' }}>French</MenuItem>
            <MenuItem value="fy" sx={{ color: '#121212' }}>Frisian</MenuItem>
            <MenuItem value="gl" sx={{ color: '#121212' }}>Galician</MenuItem>
            <MenuItem value="ka" sx={{ color: '#121212' }}>Georgian</MenuItem>
            <MenuItem value="de" sx={{ color: '#121212' }}>German</MenuItem>
            <MenuItem value="el" sx={{ color: '#121212' }}>Greek</MenuItem>
            <MenuItem value="gu" sx={{ color: '#121212' }}>Gujarati</MenuItem>
            <MenuItem value="ht" sx={{ color: '#121212' }}>Haitian Creole</MenuItem>
            <MenuItem value="ha" sx={{ color: '#121212' }}>Hausa</MenuItem>
            <MenuItem value="haw" sx={{ color: '#121212' }}>Hawaiian</MenuItem>
            <MenuItem value="iw" sx={{ color: '#121212' }}>Hebrew</MenuItem>
            <MenuItem value="hi" sx={{ color: '#121212' }}>Hindi</MenuItem>
            <MenuItem value="hmn" sx={{ color: '#121212' }}>Hmong</MenuItem>
            <MenuItem value="hu" sx={{ color: '#121212' }}>Hungarian</MenuItem>
            <MenuItem value="is" sx={{ color: '#121212' }}>Icelandic</MenuItem>
            <MenuItem value="ig" sx={{ color: '#121212' }}>Igbo</MenuItem>
            <MenuItem value="id" sx={{ color: '#121212' }}>Indonesian</MenuItem>
            <MenuItem value="ga" sx={{ color: '#121212' }}>Irish</MenuItem>
            <MenuItem value="it" sx={{ color: '#121212' }}>Italian</MenuItem>
            <MenuItem value="ja" sx={{ color: '#121212' }}>Japanese</MenuItem>
            <MenuItem value="jw" sx={{ color: '#121212' }}>Javanese</MenuItem>

            <MenuItem value="kk" sx={{ color: '#121212' }}>Kazakh</MenuItem>
            <MenuItem value="km" sx={{ color: '#121212' }}>Khmer</MenuItem>
            <MenuItem value="rw" sx={{ color: '#121212' }}>Kinyarwanda</MenuItem>
            <MenuItem value="ko" sx={{ color: '#121212' }}>Korean</MenuItem>
            <MenuItem value="ku" sx={{ color: '#121212' }}>Kurdish (Kurmanji)</MenuItem>
            <MenuItem value="ky" sx={{ color: '#121212' }}>Kyrgyz</MenuItem>
            <MenuItem value="lo" sx={{ color: '#121212' }}>Lao</MenuItem>
            <MenuItem value="la" sx={{ color: '#121212' }}>Latin</MenuItem>
            <MenuItem value="lv" sx={{ color: '#121212' }}>Latvian</MenuItem>
            <MenuItem value="lt" sx={{ color: '#121212' }}>Lithuanian</MenuItem>
            <MenuItem value="lb" sx={{ color: '#121212' }}>Luxembourgish</MenuItem>
            <MenuItem value="mk" sx={{ color: '#121212' }}>Macedonian</MenuItem>
            <MenuItem value="mg" sx={{ color: '#121212' }}>Malagasy</MenuItem>
            <MenuItem value="ms" sx={{ color: '#121212' }}>Malay</MenuItem>
            <MenuItem value="mt" sx={{ color: '#121212' }}>Maltese</MenuItem>
            <MenuItem value="mi" sx={{ color: '#121212' }}>Maori</MenuItem>

            <MenuItem value="mn" sx={{ color: '#121212' }}>Mongolian</MenuItem>
            <MenuItem value="my" sx={{ color: '#121212' }}>Myanmar (Burmese)</MenuItem>
            <MenuItem value="ne" sx={{ color: '#121212' }}>Nepali</MenuItem>
            <MenuItem value="no" sx={{ color: '#121212' }}>Norwegian</MenuItem>
            <MenuItem value="or" sx={{ color: '#121212' }}>Odia (Oriya)</MenuItem>
            <MenuItem value="ps" sx={{ color: '#121212' }}>Pashto</MenuItem>
            <MenuItem value="fa" sx={{ color: '#121212' }}>Persian</MenuItem>
            <MenuItem value="pl" sx={{ color: '#121212' }}>Polish</MenuItem>
            <MenuItem value="pt" sx={{ color: '#121212' }}>Portuguese</MenuItem>

            <MenuItem value="ro" sx={{ color: '#121212' }}>Romanian</MenuItem>
            <MenuItem value="ru" sx={{ color: '#121212' }}>Russian</MenuItem>
            <MenuItem value="sm" sx={{ color: '#121212' }}>Samoan</MenuItem>
            <MenuItem value="gd" sx={{ color: '#121212' }}>Scots Gaelic</MenuItem>
            <MenuItem value="sr" sx={{ color: '#121212' }}>Serbian</MenuItem>
            <MenuItem value="st" sx={{ color: '#121212' }}>Sesotho</MenuItem>
            <MenuItem value="sn" sx={{ color: '#121212' }}>Shona</MenuItem>
            <MenuItem value="sd" sx={{ color: '#121212' }}>Sindhi</MenuItem>
            <MenuItem value="si" sx={{ color: '#121212' }}>Sinhala</MenuItem>
            <MenuItem value="sk" sx={{ color: '#121212' }}>Slovak</MenuItem>
            <MenuItem value="sl" sx={{ color: '#121212' }}>Slovenian</MenuItem>
            <MenuItem value="so" sx={{ color: '#121212' }}>Somali</MenuItem>
            <MenuItem value="es" sx={{ color: '#121212' }}>Spanish</MenuItem>
            <MenuItem value="su" sx={{ color: '#121212' }}>Sundanese</MenuItem>
            <MenuItem value="sw" sx={{ color: '#121212' }}>Swahili</MenuItem>
            <MenuItem value="sv" sx={{ color: '#121212' }}>Swedish</MenuItem>
            <MenuItem value="tg" sx={{ color: '#121212' }}>Tajik</MenuItem>
            <MenuItem value="ta" sx={{ color: '#121212' }}>Tamil</MenuItem>
            <MenuItem value="tt" sx={{ color: '#121212' }}>Tatar</MenuItem>

            <MenuItem value="th" sx={{ color: '#121212' }}>Thai</MenuItem>
            <MenuItem value="tr" sx={{ color: '#121212' }}>Turkish</MenuItem>
            <MenuItem value="tk" sx={{ color: '#121212' }}>Turkmen</MenuItem>
            <MenuItem value="uk" sx={{ color: '#121212' }}>Ukrainian</MenuItem>
            <MenuItem value="ur" sx={{ color: '#121212' }}>Urdu</MenuItem>
            <MenuItem value="ug" sx={{ color: '#121212' }}>Uyghur</MenuItem>
            <MenuItem value="uz" sx={{ color: '#121212' }}>Uzbek</MenuItem>
            <MenuItem value="vi" sx={{ color: '#121212' }}>Vietnamese</MenuItem>
            <MenuItem value="cy" sx={{ color: '#121212' }}>Welsh</MenuItem>
            <MenuItem value="xh" sx={{ color: '#121212' }}>Xhosa</MenuItem>
            <MenuItem value="yi" sx={{ color: '#121212' }}>Yiddish</MenuItem>
            <MenuItem value="yo" sx={{ color: '#121212' }}>Yoruba</MenuItem>
            <MenuItem value="zu" sx={{ color: '#121212' }}>Zulu</MenuItem> */}
        </CssTextField>
    )
}
