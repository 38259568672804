import { configureStore } from '@reduxjs/toolkit';
import loginReducer from './slices/loginSlice';
import feedbackReducer from './slices/feedbackSlice';
import sessionReducer from './slices/sessionSlice';
import faqReducer from './slices/faqSlice';
import guidelineReducer from './slices/guidelineSlice';
import helpDeskReducer from './slices/helpDeskSlice';
import airlinesReducer from './slices/DepartureAndArrival/airlinesSlice';
import gatesReducer from './slices/DepartureAndArrival/gatesSlice';
import flightsReducer from './slices/DepartureAndArrival/flightsSlice';
import weatherReducer from './slices/weatherSlice';
import chatbotReducer from './slices/chatbotSlice';
import mapsReducer from './slices/mapsSlice';
import dineReducer from './slices/dineSlice';

export const store = configureStore({
  reducer: {
    login: loginReducer,
    feedback: feedbackReducer,
    session: sessionReducer,
    faq: faqReducer,
    guideline: guidelineReducer,
    helpDesk: helpDeskReducer,
    airlines: airlinesReducer,
    gates: gatesReducer,
    flights: flightsReducer,
    weather: weatherReducer,
    chatbot: chatbotReducer,
    maps: mapsReducer,
    dine: dineReducer,
  },
});
