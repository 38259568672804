import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendServerBaseURL } from '../../utils/backendServerBaseURL';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  logs: [],
  sessionId: uuidv4(),
  callId: null,
};

export const submitLogs = createAsyncThunk(
  'session/submitLogs',
  async (_, thunkAPI) => {
    if (thunkAPI.getState().session.logs.length !== 0) {
      const token = await localStorage.getItem("token", "")

      let config = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }

      let response = await axios.post(`${backendServerBaseURL}/kiosk/log`, {
        sessionId: thunkAPI.getState().session.sessionId,
        kioskId: JSON.parse(localStorage.getItem('kiosk', ''))._id,
        actions: thunkAPI.getState().session.logs,
        callId: thunkAPI.getState().session.callId,
      }, config);

      console.log(response.data.payload)

      thunkAPI.dispatch(reset())

      if (response.status === 200) {
        // thunkAPI.dispatch(updateDashboardStats(response.data.payload))
      }
    }
  }
);

export const reset = createAsyncThunk(
  'session/reset',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(resetState())
    thunkAPI.dispatch(updateSessionId(uuidv4()))
  }
);

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    resetState: () => initialState,
    addLog: (state, action) => {
      let tempState = [...state.logs]
      let newLog = {
        'action': action.payload,
        'time': new Date()
      }
      tempState.push(newLog)
      state.logs = tempState;
    },
    updateSessionId: (state, action) => {
      state.sessionId = action.payload;
    },
    updateCallId: (state, action) => {
      state.callId = action.payload;
    },
  }
})

export const {
  addLog,
  resetState,
  updateSessionId,
  updateCallId
} = sessionSlice.actions;

export const selectLogs = (state) => state.session.logs;
export const selectSessionId = (state) => state.session.sessionId;
export const selectCallId = (state) => state.session.callId;


export default sessionSlice.reducer;
