import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import DateRangeIcon from "@mui/icons-material/DateRange";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import HomeIcon from "@mui/icons-material/Home";
import { Box, Fab, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Offline, Online } from "react-detect-offline";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loadWeather } from "../../redux/slices/weatherSlice";
import { staticBaseURL } from "../../utils/backendServerBaseURL";
import { formatDate } from "../../utils/formatTime";
import LanguageSelector from "../MainNav/LanguageSelector";

export default function DineHeader({ poi }) {
  const naviagte = useNavigate();
  const dispatch = useDispatch();
  const [time, settime] = useState("");
  const [currentDate, setcurrentDate] = useState("");
  let [airportName, setairportName] = useState("");

  const loadDateTimeInUI = () => {
    var date = new Date();
    settime(
      date
        .toLocaleTimeString(navigator.language, {
          hour: "2-digit",
          minute: "2-digit",
        })
        .toString()
    );
    setcurrentDate(formatDate(date));
  };

  useEffect(() => {
    try {
      setairportName(JSON.parse(localStorage.getItem("airport", "")).name);
    } catch (e) {
      console.log(e);
    }

    dispatch(loadWeather());
    loadDateTimeInUI();

    const interval = setInterval(loadDateTimeInUI(), 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          zIndex: 2,
          height: "400px",
          display: "flex",
          flexWrap: "wrap",
          alignContent: "space-between",
        }}
      >
        <Box
          sx={{ width: "100%", display: "flex", alignItems: "center" }}
          p={4}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h1">Wilyer</Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <AccessTimeIcon />
            <Typography variant="body2" sx={{ marginRight: 2, marginLeft: 1 }}>
              {time}
            </Typography>

            <DateRangeIcon />
            <Typography variant="body2" sx={{ marginRight: 2, marginLeft: 1 }}>
              {currentDate}
            </Typography>

            <Offline>
              <Stack direction="row">
                <FiberManualRecordIcon color="error" />
                <Typography
                  variant="body2"
                  sx={{ marginRight: 2, marginLeft: 1 }}
                >
                  Offline
                </Typography>
              </Stack>
            </Offline>
            <Online>
              <Stack direction="row">
                <FiberManualRecordIcon color="success" />
                <Typography
                  variant="body2"
                  sx={{ marginRight: 2, marginLeft: 1 }}
                >
                  Online
                </Typography>
              </Stack>
            </Online>
          </Box>
        </Box>

        <Box
          p={2}
          m={2}
          sx={{
            minWidth: "680px",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "purple",
            opacity: 0.8,
          }}
        >
          <Box
            sx={{
              maxWidth: "500px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              component="img"
              sx={{
                height: "8rem",
                maxWidth: "200px",
                maxHeight: "200px",
                minWidth: "200px",
                minHeight: "200px",
                backgroundColor: "white",
              }}
              alt="Flights"
              src={
                poi?.imageURL != null
                  ? staticBaseURL + "/" + poi?.imageURL
                  : "/static/dine/restaurents.png"
              }
            ></Box>

            <Box ml={3} sx={{ flexGrow: 1 }}>
              <Typography variant="h3">{poi?.name}</Typography>
              <Typography>{poi?.description}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{ display: "flex", alignItems: "center" }}
        p={3}
        style={{
          background:
            "-webkit-linear-gradient(-180deg, #EA2963 1.42%, #522D99 45.69%, #089AD1 99.13%)",
        }}
      >
        <Box mr={2}>
          <Fab
            color="primary"
            sx={{ borderRadius: 2, marginRight: 1 }}
            onClick={() => {
              naviagte(-1);
            }}
          >
            <ArrowBackIosNewIcon />
          </Fab>

          <Fab
            color="primary"
            sx={{ borderRadius: 2, marginRight: 1 }}
            onClick={() => {
              naviagte("/main-nav");
            }}
          >
            <HomeIcon />
          </Fab>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <Typography>Welcome to</Typography>
          <Typography variant="h3">{airportName} Airport</Typography>
        </Box>

        <Box pt={2}>
          <LanguageSelector />
        </Box>
      </Box>
    </>
  );
}
