import axios from 'axios';

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
    (response) => {
        console.log('%c 👇 ' + response.config.method + " " + response.request.responseURL, 'color:green; border:0.5px solid gray; padding: 8px; border-radius: 0.4rem')
        console.log(response)
        return response
    },

    (error) => {
        console.log('%c 👇 ' + error.response.request.responseURL, 'color:red; border:0.5px solid gray; padding: 8px; border-radius: 0.4rem')
        console.log(error.response)
        throw error
    }
);

export default axiosInstance;
