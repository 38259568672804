import { Box, Button, IconButton, Stack, TextField, Typography } from '@mui/material';
import React, { useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { selectError, updateError } from '../redux/slices/loginSlice';

import Keyboard from 'react-simple-keyboard';
import '../onScreenKeyboard.css';

import { useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';

export default function Login({ socket }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const error = useSelector(selectError)
  const terminalIdRef = useRef('')
  const [showKeyboard, setshowKeyboard] = useState(false)

  const onChange = (input) => {
    console.log("Input changed", input);
    terminalIdRef.current.value = input
  }

  const onKeyPress = (button) => {
    console.log("Button pressed", button);
  }


  useMemo(() => {
    socket.on("createKiosk", (payload) => {
      console.log('createKiosk ', payload)

      localStorage.setItem('terminal', JSON.stringify(payload.terminal))
      localStorage.setItem('airport', JSON.stringify(payload.airport))
      localStorage.setItem('kiosk', JSON.stringify(payload.kiosk))

      navigate('/')
    });

    socket.on("createKioskError", (payload) => {
      console.log('createKioskError ', payload)

      dispatch(updateError(payload.message))
    });

    return ''
  }, []);

  const connect = () => {
    setshowKeyboard(false)

    if (terminalIdRef.current.value.toString().length !== 0) {
      dispatch(updateError(''))
      console.log(terminalIdRef.current.value)

      socket.emit('createKiosk', {
        uniqueCode: terminalIdRef.current.value
      })
    } else {
      dispatch(updateError('Terminal code is required'))
    }
  }

  return (
    <>
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Stack sx={{ width: '60%', backgroundColor: 'white', opacity: 0.95, padding: 4, borderRadius: 1 }} spacing={2}>
          <Typography variant='h3' textAlign='center' color='text.disabled'>This kiosk is not connected to terminal. Please enter terminal id to connect this kiosk to terminal.</Typography>
          <TextField
            inputProps={{
              autoComplete: 'off'
            }}
            onFocus={() => { setshowKeyboard(true) }}
            inputRef={terminalIdRef}
            label="Terminal ID"
            variant="outlined"
            size='large'
            sx={{ input: { color: '#121212' } }}
          />
          <Button variant='contained' onClick={connect}>Connect</Button>

          <Typography variant='body2' textAlign='center' color='error'>{error}</Typography>
        </Stack>
      </Box>

      {
        showKeyboard && <Box sx={{ position: 'fixed', bottom: 0, left: 0, backgroundColor: 'white', minWidth: '100%', borderTopRightRadius: 20, borderTopLeftRadius: 20 }}>
          <Box sx={{ minWidth: '670px', padding: 5 }}>
            <Box textAlign='right'>
              <IconButton onClick={() => { setshowKeyboard(false) }}>
                <CancelIcon sx={{ color: '#121212', fontSize: '2rem', position: 'relative', top: -10 }} />
              </IconButton>
            </Box>

            <Keyboard
              style={{ color: 'black' }}
              onChange={input =>
                onChange(input)}
              onKeyPress={button =>
                onKeyPress(button)}
            />

          </Box>
        </Box>
      }
    </>
  )
}
