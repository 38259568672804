import { Box, Container, Grid, Typography, Stack, Button } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/MainNav/Header';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectSelectedFAQ } from '../../redux/slices/faqSlice';

export default function FAQsAnswer() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const selectedFAQ = useSelector(selectSelectedFAQ)

    return (
        <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
            <Header />

            <Box sx={{ minHeight: '100%', backgroundColor: '#ffffff96', flexGrow: 1 }}>
                <Box p={3}>
                    <Container sx={{ minHeight: '750px' }}>
                        <Box pt={3} pb={3}>
                            <Typography variant='h2' color='black'>{selectedFAQ.question}</Typography>
                        </Box>


                        <Box pt={3} pb={3}>
                            <Typography variant='h3' color='black' dangerouslySetInnerHTML={{ __html: selectedFAQ.answer }} />
                        </Box>

                    </Container>

                    <Stack spacing={1}>
                        <Button variant='contained' color='success' size='large' onClick={() => { navigate('/help-desk/help') }} sx={{ width: '680px', color: 'white', fontSize: 20 }}>Want to Connect to an Agent Via Video Call</Button>
                    </Stack>
                </Box>
            </Box>


        </Box>
    )
}
