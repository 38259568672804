import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../utils/axios';
import { backendServerBaseURL } from '../../../utils/backendServerBaseURL';

const initialState = {
  flights: []
};

export const loadFlights = createAsyncThunk(
  'flights/loadFlights',
  async (payload, thunkAPI) => {
    console.log(payload)
    let config = {
      params: {

      },
      headers: {
        'X-apiKey': 'AAAAAAAAAAAAAAAAAAAAAAAAAAA'
      }
    }

    const airportCode = JSON.parse(localStorage.getItem('airport', ''))['ICAO']

    let response = await axios.get(`/flightinfo/v1/flights/airport/:airportCode/direction/:direction${payload.direction}`, config);

    if (response.status === 200) {
      thunkAPI.dispatch(updateFlights(response.data.data.flightRecords))
    }
  }
);

export const flightsSlice = createSlice({
  name: 'flights',
  initialState,
  reducers: {
    updateFlights: (state, action) => {
      state.flights = action.payload;
    },
  }
})

export const {
  updateFlights,
} = flightsSlice.actions;

export const selectFlights = (state) => state.flights.flights;


export default flightsSlice.reducer;
