import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../utils/axios';
import { backendServerBaseURL } from '../../../utils/backendServerBaseURL';

const initialState = {
  flights: [],
  gates: [],
};

export const loadFlights = createAsyncThunk(
  'gates/loadFlights',
  async (payload, thunkAPI) => {
    console.log(payload)
    let config = {
      params: {

      },
      headers: {
        'X-apiKey': 'AAAAAAAAAAAAAAAAAAAAAAAAAAA'
      }
    }

    const airportCode = JSON.parse(localStorage.getItem('airport', ''))['ICAO']

    let response = await axios.get(`/flightinfo/v1/flights/airport/:airportCode/direction/:direction${payload.direction}`, config);

    if (response.status === 200) {
      thunkAPI.dispatch(updateFlights(response.data.data.flightRecords))
      const tempGates = response.data.data.flightRecords.map(data => data.arrival.gate)
      var unique = tempGates.filter(function (item, i, ar) { return ar.indexOf(item) === i; });
      thunkAPI.dispatch(updateGates(unique))
    }
  }
);

export const gatesSlice = createSlice({
  name: 'gates',
  initialState,
  reducers: {
    updateFlights: (state, action) => {
      state.flights = action.payload;
    },
    updateGates: (state, action) => {
      state.gates = action.payload;
    },
  }
})

export const {
  updateFlights,
  updateGates,
} = gatesSlice.actions;

export const selectFlights = (state) => state.gates.flights;
export const selectGates = (state) => state.gates.gates;


export default gatesSlice.reducer;
