import { Box, Container, Grid, Typography, Stack, Button } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/MainNav/Header';
import { loadFAQsCategories, selectFaqsCategories } from '../../redux/slices/faqSlice';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { staticBaseURL } from '../../utils/backendServerBaseURL';
import { addLog } from '../../redux/slices/sessionSlice';


export default function FAQsCategories() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const faqsCategories = useSelector(selectFaqsCategories)

    useEffect(() => {
        dispatch(loadFAQsCategories())
    }, [])

    return (
        <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
            <Header />

            <Box sx={{ minHeight: '100%', backgroundColor: '#ffffff96', flexGrow: 1 }}>
                <Box p={3}>
                    <Container sx={{ minHeight: '750px' }}>
                        <Box pt={3} pb={3}>
                            <Typography variant='h3' color='black'>FAQs</Typography>
                        </Box>


                        <Grid container spacing={2}>
                            {
                                faqsCategories.map(category => {
                                    return <Grid
                                        item
                                        xs={4}
                                        onClick={() => {
                                            dispatch(addLog(`Opened FAQ category - ${category.name}`));
                                            navigate(`/faqs-questions/${category.name}/${category._id}`)
                                        }}
                                    >
                                        <Box
                                            component="img"
                                            p={2}
                                            sx={{
                                                height: '10rem',
                                                maxWidth: '100%',
                                                backgroundColor: 'white'
                                            }}
                                            alt="Flights"
                                            src={staticBaseURL + '/' + category.imageURL}
                                        >
                                        </Box>

                                        <Box p={2} sx={{ position: 'relative', top: -25, backgroundColor: 'black', opacity: 0.7, minWidth: '100%' }}>
                                            <Typography variant='h4' textAlign={'center'}>{category.name}</Typography>
                                        </Box>
                                    </Grid>
                                })
                            }

                        </Grid>

                    </Container>

                    <Stack spacing={1}>
                        <Button variant='contained' color='success' size='large' onClick={() => { navigate('/help-desk/help') }} sx={{ width: '680px', color: 'white', fontSize: 20 }}>Want to Connect to an Agent Via Video Call</Button>
                    </Stack>
                </Box>
            </Box>


        </Box>
    )
}
