import { Box, Button, Stack, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { Component } from "react";
import HelpDeskHeader from "../../components/HelpDesk/HelpDeskHeader";
import { updateCallId } from "../../redux/slices/sessionSlice";
import { Peerlib } from "../../utils/peer";
import { withRouter } from "../../utils/withRouter";

class HelpDesk extends Component {
  constructor(props) {
    super(props);
    console.log("AAAAAAAAA");
    console.log(props);
    let { callType } = props.router.params;
    this.callType = callType;

    this.myVideoRef = React.createRef();
    this.agentVideoRef = React.createRef();

    this.peer = null;

    this.callId = props.redux.callId;
    this.sessionLogs = props.redux.sessionLogs;
    this.sessionId = props.redux.sessionId;

    this.mySocketId = localStorage.getItem("mySocketId", "");
    this.airportId = JSON.parse(localStorage.getItem("airport", ""))["_id"];
    this.terminalId = JSON.parse(localStorage.getItem("terminal", ""))["_id"];
    this._60SecTimer = null;

    // Bind function
    this.cleanup = this.cleanup.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.callAgent = this.callAgent.bind(this);
    this.endCall = this.endCall.bind(this);
    this.endCalling = this.endCalling.bind(this);
  }

  state = {
    open: false,
    agentSocketId: null,
    stream: null,
    callAccepted: false,
    calling: false,

    callerId: null,
    receivingCall: false,
    callEnded: false,
    agents: [],
    activeAgent: null,
  };

  cleanup = () => {
    if (this.state.stream) {
      this.state.stream.getTracks().forEach(function (track) {
        if (track.readyState === "live") {
          track.stop();
        }
      });
    }

    if (this.state.callAccepted) {
      this.props.socket.emit("endCall", {
        me: this.mySocketId,
        to: this.state.agentSocketId,
        callId: this.callId,
      });
    }

    if (this.state.calling) {
      this.props.socket.emit("endCalling", {
        me: this.mySocketId,
      });
    }
  };

  componentWillUnmount = () => {
    this.cleanup();
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  componentDidMount = () => {
    this.props.socket.off("callAccepted");
    this.props.socket.off("callEnded");
    this.props.socket.off("getCallId");
    this.props.socket.off("noAgentsAvailable");

    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then(async (stream) => {
        await this.setState({ stream });

        this.myVideoRef.current.srcObject = stream;
        this.myVideoRef.current.muted = true;

        if (this.state.calling === false && this.callType === "emergency") {
          this.callAgent();
        }
      });
  };

  callAgent = () => {
    this.peer = new Peerlib(this.state.stream);

    this.setState({ calling: true });

    // Ring time
    if (this._60SecTimer !== null) {
      clearTimeout(this._60SecTimer);
    }

    this._60SecTimer = setTimeout(() => {
      if (this.state.callAccepted === false) {
        this.endCalling();
      }
    }, 60000);

    // on peer signal
    this.peer.onSignal((data) => {
      this.props.socket.emit("callUser", {
        signalData: data,
        from: this.mySocketId,
        airportId: this.airportId,
        terminalId: this.terminalId,
        callType: this.callType,
        sessionLogs: this.sessionLogs,
        sessionId: this.sessionId,
      });
    });

    // peer on stream
    this.peer.onStream((stream) => {
      console.log(stream);
      this.agentVideoRef.current.srcObject = stream;
    });

    // on call Accepted
    this.props.socket.on("callAccepted", (data) => {
      clearTimeout(this._60SecTimer);
      this.peer.peer.signal(data.signal);

      this.setState({
        callAccepted: true,
        calling: false,
        agentSocketId: data.agentSocketId,
      });
    });

    // on call ended
    this.props.socket.on("callEnded", (data) => {
      // peer.peer.destroy()

      if (this.state.stream) {
        this.state.stream.getTracks().forEach(function (track) {
          if (track.readyState === "live") {
            track.stop();
          }
        });
      }

      if (this.state.callAccepted) {
        this.props.router.navigate("/help-desk-feedback");
      } else {
        this.props.router.navigate("/main-nav");
      }

      this.setState({
        calling: false,
        stream: null,
        callAccepted: false,
      });
    });

    // Save call ID for logs
    this.props.socket.on("getCallId", (data) => {
      const CallId = data.callId;
      this.props.redux.dispatch(updateCallId(CallId));
    });

    // No agents available
    this.props.socket.on("noAgentsAvailable", (data) => {
      this.handleClickOpen();
    });
  };

  endCall = () => {
    this.props.socket.emit("endCall", {
      me: this.mySocketId,
      to: this.state.agentSocketId,
      callId: this.callId,
    });
  };

  endCalling = () => {
    this.props.socket.emit("endCalling", {
      me: this.mySocketId,
    });
  };

  render() {
    return (
      <>
        <HelpDeskHeader />

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "120px",
          }}
        >
          <Stack sx={{ width: "100%" }}>
            {/* Agent video stream */}
            {!this.state.callAccepted && (
              <Box
                style={{
                  maxWidth: "100%",
                  minWidth: "100%",
                  minHeight: "580px",
                  background:
                    "-webkit-linear-gradient(-180deg, #EA2963 1.42%, #522D99 45.69%, #089AD1 99.13%)",
                }}
              ></Box>
            )}

            {this.state.callAccepted && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <video
                  autoPlay
                  playsInline
                  ref={this.agentVideoRef}
                  style={{
                    maxWidth: "100%",
                    minWidth: "100%",
                    height: "580px",
                    objectFit: "fill",
                  }}
                />
              </Box>
            )}

            {this.state.callAccepted && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box
                  pb={8}
                  sx={{
                    position: "fixed",
                    bottom: 0,
                    width: "100%",
                    zIndex: 99,
                  }}
                >
                  <Box textAlign={"center"} mt={4}>
                    <Button
                      onClick={() => {
                        this.endCall();
                      }}
                      variant="contained"
                      color="error"
                      size="large"
                      sx={{ color: "white", fontSize: 32, padding: 5 }}
                    >
                      END CALL
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}

            {/* My video stream */}
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <video
                autoPlay
                playsInline
                ref={this.myVideoRef}
                style={{
                  maxWidth: "100%",
                  minWidth: "100%",
                  height: "580px",
                  objectFit: "fill",
                }}
              />
            </Box>

            {this.state.calling && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  position: "fixed",
                  bottom: 0,
                  paddingBottom: 8,
                  width: "100%",
                  zIndex: 99,
                }}
              >
                <Stack spacing={3}>
                  <Typography variant="h3" textAlign="center">
                    Calling
                  </Typography>

                  <Box textAlign={"center"}>
                    <Button
                      onClick={() => {
                        this.endCalling();
                      }}
                      variant="contained"
                      color="error"
                      size="large"
                      sx={{ color: "white", fontSize: 32, padding: 5 }}
                    >
                      END CALL
                    </Button>
                  </Box>
                </Stack>
              </Box>
            )}
            {!this.state.calling &&
              !this.state.callAccepted &&
              this.callType !== "emergency" && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    position: "fixed",
                    bottom: 0,
                    paddingBottom: 8,
                    width: "100%",
                    zIndex: 99,
                  }}
                >
                  <Box textAlign={"center"}>
                    <Button
                      onClick={() => {
                        this.callAgent();
                      }}
                      variant="contained"
                      color="success"
                      size="large"
                      sx={{ color: "white", fontSize: 32, padding: 5 }}
                    >
                      CALL AGENT
                    </Button>
                  </Box>
                </Box>
              )}
          </Stack>
        </Box>

        {/* No Agents Available Dialog */}
        <div>
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" color="black">
              <Typography sx={{ color: "black", fontSize: 40 }}>
                No Agents Available
              </Typography>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Typography sx={{ color: "black", fontSize: 20 }}>
                  There are no agents available to receive your call. Sorry for
                  inconvenience.
                </Typography>
              </DialogContentText>

              <Box textAlign="center" mt={5}>
                <Button
                  variant="contained"
                  sx={{ fontSize: 30, minWidth: "300px" }}
                  onClick={() => {
                    this.cleanup();
                  }}
                >
                  Ok
                </Button>
              </Box>
            </DialogContent>
          </Dialog>
        </div>
      </>
    );
  }
}

export default withRouter(HelpDesk);
