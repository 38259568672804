import { Box, Button, Container, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function Page404() {
  return (
    <Container
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h3" paragraph>
          Sorry, page not found!
        </Typography>

        <Typography sx={{ color: "text.secondary" }}>
          Sorry, we couldn’t find the page you’re looking for. <br />
          Perhaps you’ve mistyped the URL? Be sure to check your spelling.
        </Typography>

        <Box
          textAlign="center"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Box
            component="img"
            sx={{
              height: 200,
              width: 200,
            }}
            src="/static/error/404-error.svg"
          />
        </Box>

        <Button to="/" size="large" variant="contained" component={RouterLink}>
          Go to Home
        </Button>
      </Box>
    </Container>
  );
}
