import { Navigate, useRoutes } from "react-router-dom";
import Flights from "../pages/Departure/Flights";
import Gates from "../pages/Departure/Gates";
import Home from "../pages/Home";
import Login from "../pages/Login";
import MainNav from "../pages/MainNav";
import Page404 from "../pages/Page404";
import { useLocation } from 'react-router-dom';
import HelpDesk from "../pages/HelpDesk/HelpDesk";
import Feedback from "../pages/Feedback";
import ThankYouFeedback from "../pages/ThankYouFeedback";
import Guidelines from "../pages/Guidelines";
import Maps from "../pages/Maps";
import Departure from "../pages/Departure/Departure";
import Airlines from "../pages/Departure/Airlines";
import Dine from "../pages/Dine/Dine";
import Services from "../pages/Services/Services";
import DineOptions from "../pages/Dine/DineOptions";
import HelpDeskFeedback from "../pages/HelpDesk/HelpDeskFeedback";
import FAQsCategories from "../pages/FAQs/FAQsCategories";
import FAQsQuestions from "../pages/FAQs/FAQsQuestions";
import FAQsAnswer from "../pages/FAQs/FAQsAnswer";
import ScanQRCode from "../pages/ScanQRCode/ScanQRCode";
import QRCodeResult from "../pages/ScanQRCode/QRCodeResult";
import FlightsByGate from "../pages/Departure/FlightsByGate";
import FlightsByAirline from "../pages/Departure/FlightsByAirline";
import Chatbot from "../pages/Chatbot";
import DineMap from "../pages/Dine/DineMap";


function RequireAuth({ children }) {
  const kioskData = localStorage.getItem("kiosk", null);
  const location = useLocation()

  if (kioskData == null || kioskData === undefined) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export default function Router({ socket }) {
  return useRoutes([
    {
      path: "/",
      element: <RequireAuth><Home socket={socket} /></RequireAuth>,
      children: [],
    },

    {
      path: "main-nav",
      element: <RequireAuth><MainNav /></RequireAuth>,
      children: [],
    },

    {
      path: "thank-you-feedback",
      element: <RequireAuth><ThankYouFeedback /></RequireAuth>,
      children: [],
    },

    {
      path: "login",
      element: <Login socket={socket} />,
      children: [],
    },

    {
      path: "help-desk/:callType",
      element: <RequireAuth><HelpDesk socket={socket} /></RequireAuth>,
      children: [],
    },

    {
      path: "help-desk-feedback",
      element: <RequireAuth><HelpDeskFeedback socket={socket} /></RequireAuth>,
      children: [],
    },

    {
      path: "feedback",
      element: <RequireAuth><Feedback socket={socket} /></RequireAuth>,
      children: [],
    },

    {
      path: "faqs",
      element: <RequireAuth><FAQsCategories socket={socket} /></RequireAuth>,
      children: [],
    },

    {
      path: "faqs-questions/:categoryName/:categoryId",
      element: <RequireAuth><FAQsQuestions socket={socket} /></RequireAuth>,
      children: [],
    },

    {
      path: "faqs-answer",
      element: <RequireAuth><FAQsAnswer socket={socket} /></RequireAuth>,
      children: [],
    },

    {
      path: "maps",
      element: <RequireAuth><Maps socket={socket} /></RequireAuth>,
      children: [],
    },

    {
      path: "guidelines",
      element: <RequireAuth><Guidelines socket={socket} /></RequireAuth>,
      children: [],
    },

    {
      path: "direction/:direction",
      element: <RequireAuth><Departure socket={socket} /></RequireAuth>,
      children: [],
    },
    {
      path: "flights/:direction",
      element: <RequireAuth><Flights socket={socket} /></RequireAuth>,
    },
    {
      path: "gates/:direction",
      element: <RequireAuth><Gates socket={socket} /></RequireAuth>,
    },
    {
      path: "gates/:direction/:gateNumber/flights",
      element: <RequireAuth><FlightsByGate socket={socket} /></RequireAuth>,
    },

    {
      path: "airlines/:direction",
      element: <RequireAuth><Airlines socket={socket} /></RequireAuth>,
    },
    {
      path: "airlines/:direction/:airlineIATACode/flights",
      element: <RequireAuth><FlightsByAirline socket={socket} /></RequireAuth>,
    },

    {
      path: "dine",
      element: <RequireAuth><Dine socket={socket} /></RequireAuth>,
      children: [],
    },

    {
      path: "/dine-options/:name",
      element: <RequireAuth><DineOptions socket={socket} /></RequireAuth>,
      children: [],
    },

    {
      path: "/dine-map",
      element: <RequireAuth><DineMap socket={socket} /></RequireAuth>,
      children: [],
    },

    {
      path: "services",
      element: <RequireAuth><Services socket={socket} /></RequireAuth>,
      children: [],
    },

    // Scan QR Code
    {
      path: "scan-qr-code",
      element: <RequireAuth><ScanQRCode socket={socket} /></RequireAuth>,
      children: [],
    },
    {
      path: "qr-result",
      element: <RequireAuth><QRCodeResult socket={socket} /></RequireAuth>,
      children: [],
    },

    // chatbot
    {
      path: "chatbot",
      element: <RequireAuth><Chatbot socket={socket} /></RequireAuth>,
      children: [],
    },

    // 404
    {
      path: "404",
      element: <Page404 />,
      children: [],
    },

    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);
}
