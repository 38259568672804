import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/MainNav/Header";
import {
  loadAirlines,
  loadFlights,
  selectAirlines,
} from "../../redux/slices/DepartureAndArrival/airlinesSlice";

export default function Airlines() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const airlines = useSelector(selectAirlines);
  const params = useParams();

  useEffect(() => {
    dispatch(loadAirlines({ direction: params.direction }));
    dispatch(loadFlights({ direction: params.direction }));
  }, []);

  return (
    <Box sx={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <Header />

      <Box
        sx={{ minHeight: "100%", backgroundColor: "#ffffff96", flexGrow: 1 }}
      >
        <Box p={3}>
          <Container>
            <Box pt={3} pb={3}>
              <Typography variant="h3" color="black">
                Airlines
              </Typography>
            </Box>

            <Grid container spacing={2}>
              {airlines.map((airlineData) => {
                return (
                  <Grid
                    item
                    xs={4}
                    onClick={() => {
                      navigate(
                        `/airlines/${params.direction}/${airlineData.iataCode}/flights`
                      );
                    }}
                  >
                    <Box
                      component="img"
                      p={2}
                      sx={{
                        height: "10rem",
                        width: "100%",
                        backgroundColor: "white",
                      }}
                      alt="Flights"
                      src="/static/departure/defaultAirline.webp"
                    ></Box>

                    <Box
                      p={2}
                      sx={{
                        position: "relative",
                        top: -25,
                        backgroundColor: "black",
                        opacity: 0.7,
                        minWidth: "100%",
                      }}
                    >
                      <Typography variant="h4" textAlign={"center"}>
                        {airlineData.name}
                      </Typography>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Container>
        </Box>
      </Box>
    </Box>
  );
}
