import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendServerBaseURL } from '../../utils/backendServerBaseURL';

const initialState = {
  feedbackQuestions: [],
  currentFeedbackQuestionIndex: 0
};

export const loadFeedbackQuestions = createAsyncThunk(
  'feedback/loadFeedbackQuestions',
  async (_, thunkAPI) => {
    const kioskData = JSON.parse(localStorage.getItem("kiosk", ""))

    let config = {
      params: {
        id: kioskData._id
      }
    }

    let response = await axios.get(`${backendServerBaseURL}/kiosk/feedback/question/read`, config);

    if (response.status === 200) {
      thunkAPI.dispatch(updateFeedbackQuestions(response.data.payload.feedbackQuestions))
    }
  }
);

export const submitAnswer = createAsyncThunk(
  'feedback/submitAnswer',
  async (payload, thunkAPI) => {
    const kioskData = JSON.parse(localStorage.getItem("kiosk", ""))

    let config = {
    }

    let response = await axios.post(`${backendServerBaseURL}/kiosk/feedback`, {
      id: kioskData._id,
      question: payload.question,
      answer: payload.answer,
    }, config);

    if (response.status === 200) {
      thunkAPI.dispatch(increaseCurrentFeedbackQuestionIndex())
    }
  }
);

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    updateFeedbackQuestions: (state, action) => {
      state.feedbackQuestions = action.payload;
    },
    increaseCurrentFeedbackQuestionIndex: (state, action) => {
      state.currentFeedbackQuestionIndex = state.currentFeedbackQuestionIndex + 1;
    },
    updateCurrentFeedbackQuestionIndex: (state, action) => {
      state.currentFeedbackQuestionIndex = action.payload
    },
  }
})

export const {
  updateFeedbackQuestions,
  increaseCurrentFeedbackQuestionIndex,
  updateCurrentFeedbackQuestionIndex
} = feedbackSlice.actions;

export const selectFeedbackQuestions = (state) => state.feedback.feedbackQuestions;
export const selectCurrentFeedbackQuestionIndex = (state) => state.feedback.currentFeedbackQuestionIndex;


export default feedbackSlice.reducer;
