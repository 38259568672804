import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendServerBaseURL } from '../../utils/backendServerBaseURL';


const initialState = {
  floors: [],
};

export const loadAllFloors = createAsyncThunk(
  'mapsSlice/loadAllFloors',
  async (_, thunkAPI) => {
    const token = await localStorage.getItem("token", "")
    const airportData = JSON.parse(localStorage.getItem("airport", ""))

    let config = {
      params: {

      },
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.get(`${backendServerBaseURL}/kiosk/floor/read/${airportData._id}`, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      thunkAPI.dispatch(updateFloors(response.data.payload.floors))
    }
  }
);

export const mapsSlice = createSlice({
  name: 'maps',
  initialState,
  reducers: {
    updateFloors: (state, action) => {
      state.floors = action.payload;
    }
  }
})

export const {
  updateFloors
} = mapsSlice.actions;

export const selectFloors = (state) => state.maps.floors;

export default mapsSlice.reducer;
