import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendServerBaseURL } from '../../utils/backendServerBaseURL';

const initialState = {
  selectedPOI: null
};



export const dineSlice = createSlice({
  name: 'dine',
  initialState,
  reducers: {
    updateSelectedPOI: (state, action) => {
      state.selectedPOI = action.payload;
    },
  }
})

export const {
  updateSelectedPOI,
} = dineSlice.actions;

export const selectSelectedPOI = (state) => state.dine.selectedPOI;

export default dineSlice.reducer;
