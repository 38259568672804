import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendServerBaseURL } from '../../utils/backendServerBaseURL';

const initialState = {
  error: '',
};

// export const loadDashboardStats = createAsyncThunk(
//   'login/loadDashboardStats',
//   async (_, thunkAPI) => {
//     const token = await localStorage.getItem("token", "")

//     let config = {
//       params: {},
//       headers: {
//         'Authorization': `Bearer ${token}`
//       }
//     }

//     let response = await axios.get(`${backendServerBaseURL}/admin/login/stats`, config);

//     console.log(response.data.payload)

//     if (response.status === 200) {
//       thunkAPI.dispatch(updateDashboardStats(response.data.payload))
//     }
//   }
// );

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    updateError: (state, action) => {
      state.error = action.payload;
    },
  }
})

export const {
  updateError,
} = loginSlice.actions;

export const selectError = (state) => state.login.error;


export default loginSlice.reducer;
