import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendServerBaseURL } from '../../utils/backendServerBaseURL';
import { v4 as uuidv4 } from 'uuid';

const initialState = {

};

export const submitCallFeedback = createAsyncThunk(
  'helpDesk/submitCallFeedback',
  async (payload, thunkAPI) => {

    const token = await localStorage.getItem("token", "")

    let config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    let response = await axios.put(`${backendServerBaseURL}/kiosk/call/feedback/update`, {
      kioskId: JSON.parse(localStorage.getItem('kiosk', ''))._id,
      stars: payload.stars,
      callId: thunkAPI.getState().session.callId,
    }, config);

    console.log(response.data.payload)

    if (response.status === 200) {
      payload.navigate('/main-nav')
    }
  }
);



export const helpDeskSlice = createSlice({
  name: 'helpDesk',
  initialState,
  reducers: {
    resetState: () => initialState,

  }
})

export const {

} = helpDeskSlice.actions;



export default helpDeskSlice.reducer;
