import { Box, Typography } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import ChatbotHeader from '../components/Chatbot/ChatbotHeader'
import HelpDeskHeader from '../components/HelpDesk/HelpDeskHeader'
import { loadChatbot } from '../redux/slices/chatbotSlice'
import { useState } from 'react';
import { Button } from '@mui/material'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Card, Stack } from '@mui/material'
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom'

export default function Chatbot({ socket }) {
    const dispatch = useDispatch()
    const [chatbotData, setchatbotData] = useState([])
    const [conversation, setconversation] = useState([])
    const naviagte = useNavigate()

    React.useEffect(async () => {
        const chatbotValue = await dispatch(loadChatbot())
        console.log(chatbotValue.payload)
        setchatbotData(chatbotValue.payload)

        setconversation([])

        setconversation(oldState => {
            const tempState = [...oldState]
            tempState.push(chatbotValue.payload)
            return tempState
        })
    }, [])

    React.useEffect(() => { console.log(conversation) }, [conversation])

    return (
        <>
            <ChatbotHeader />

            <Box
                p={2}
                sx={{
                    minWidth: '100%',
                    minHeight: '900px',
                    backgroundColor: '#F3F3F3'
                }}
            >
                {
                    conversation.map(singleConversation => {
                        return singleConversation.map(block => {
                            if (block.type === 'link') {
                                return <Box mb={2} sx={{ display: 'flex' }} key={uuidv4()}>

                                    <Button
                                        sx={{ borderRadius: 100 }}
                                        variant='contained'
                                        onClick={() => {
                                            setconversation(oldState => {
                                                const tempState = [...oldState]
                                                tempState.push([{
                                                    text: block.question,
                                                    type: 'userSelection'
                                                }])
                                                return tempState
                                            })

                                            setconversation(oldState => {
                                                const tempState = [...oldState]
                                                tempState.push(block.options)
                                                return tempState
                                            })
                                        }}>{block.question}</Button>
                                </Box>
                            }

                            if (block.type === 'text') {
                                return <Box mb={2} sx={{ display: 'flex' }}>
                                    <Card sx={{ padding: 2 }}>
                                        <Box mb={1}>
                                            <Typography variant='h6' color='black'>{block.question}</Typography>
                                        </Box>

                                        {
                                            block.options.map(option => {
                                                return <Box mb={1} key={uuidv4()}>
                                                    <Button
                                                        variant='contained'
                                                        sx={{ borderRadius: 100 }}
                                                        onClick={() => {
                                                            setconversation(oldState => {
                                                                const tempState = [...oldState]
                                                                tempState.push([{
                                                                    text: option.question,
                                                                    type: 'userSelection'
                                                                }])
                                                                return tempState
                                                            })

                                                            setconversation(oldState => {
                                                                const tempState = [...oldState]
                                                                tempState.push(option.options)
                                                                return tempState
                                                            })
                                                        }}>{option.question}</Button>
                                                </Box>
                                            })
                                        }
                                    </Card>

                                    <Box sx={{ flexGrow: 1 }}></Box>
                                </Box>
                            }

                            if (block.type === 'answer') {
                                return <Box mb={2} sx={{ display: 'flex' }}>
                                    <Card sx={{ padding: 2, backgroundColor: '#D5FFE0' }}>
                                        <Box>
                                            <Typography variant='body1' color='black'>{block.answer}</Typography>
                                        </Box>
                                    </Card>


                                    <Box sx={{ flexGrow: 1 }}></Box>
                                </Box>
                            }

                            if (block.type === 'userSelection') {
                                return <Box sx={{ width: '100%', display: 'flex' }} mb={2}>
                                    <Box sx={{ flexGrow: 1 }}></Box>

                                    <Card sx={{ padding: 2 }}>
                                        <Typography variant='body1' color='black'>{block.text}</Typography>
                                    </Card>

                                </Box>
                            }
                        })
                    })
                }
            </Box>

            <Box
                p={3}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    minWidth: '100%',
                    backgroundColor: '#F3F3F3'
                }}
            >
                <Stack spacing={1}>
                    <Button variant='contained' color='success' size='large' onClick={() => { window.open('/chatbot', '_self') }} sx={{ width: '680px', color: 'white', fontSize: 20 }}>Start chat again</Button>
                    <Button variant='contained' color='success' size='large' onClick={() => { naviagte('/help-desk/help') }} sx={{ width: '680px', color: 'white', fontSize: 20 }}>Want to Connect to an Agent Via Video Call</Button>
                </Stack>
            </Box>
        </>
    )
}
