import { Box, Container, Grid, Typography, Button } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/MainNav/Header';
import FlightIcon from '@mui/icons-material/Flight';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

export default function QRCodeResult() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    return (
        <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
            <Header />

            <Box sx={{ minHeight: '100%', backgroundColor: '#ffffff96', flexGrow: 1 }}>
                <Box p={3}>
                    <Container>
                        <Box pt={3} pb={3}>
                            <Typography variant='h3' color='#121212'>Your flight information</Typography>
                        </Box>


                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <Typography variant='h1' color='#121212'>XYZ</Typography>
                                <Typography color='#3d3d3d'>New Delhi</Typography>
                            </Grid>
                            <Grid item xs={4} textAlign='center'>
                                <FlightIcon style={{ transform: 'rotate(90deg)', fontSize: '5rem' }} />
                            </Grid>
                            <Grid item xs={4} textAlign='right'>
                                <Typography variant='h1' color='#121212'>XYZ</Typography>
                                <Typography color='#3d3d3d'>Chandigarth</Typography>
                            </Grid>

                            <Grid item xs={6} >
                                <Box sx={{ backgroundColor: 'white', opacity: 0.8 }} p={2}>
                                    <DateRangeIcon sx={{ color: 'black' }} />
                                    <Typography variant='h6' color='#121212'>Date</Typography>
                                    <Typography color='#3d3d3d'>May 22, 2022</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ backgroundColor: 'white', opacity: 0.8 }} p={2}>
                                    <AccessTimeIcon sx={{ color: 'black' }} />
                                    <Typography variant='h6' color='#121212'>Time</Typography>
                                    <Typography color='#3d3d3d'>12.00 PM</Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={6} >
                                <Box p={2} textAlign='center'>
                                    <Typography variant='h4' color='#3d3d3d' sx={{ marginBottom: 2 }}>Gate</Typography>
                                    <Typography variant='h2' color='#121212'>A4</Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={6} >
                                <Box p={2} textAlign='center'>
                                    <Typography variant='h4' color='#3d3d3d' sx={{ marginBottom: 2 }}>Seat</Typography>
                                    <Typography variant='h2' color='#121212'>19A</Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={6} >
                                <Box p={2} textAlign='center'>
                                    <Typography variant='h4' color='#3d3d3d' sx={{ marginBottom: 2 }}>Flight number</Typography>
                                    <Typography variant='h2' color='#121212'>B231</Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={6} >
                                <Box p={2} textAlign='center'>
                                    <Typography variant='h4' color='#3d3d3d' sx={{ marginBottom: 2 }}>Class</Typography>
                                    <Typography variant='h2' color='#121212'>Economy</Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} >
                                <Box p={2} mb={2} textAlign='center' sx={{ width: '100%', backgroundColor: '#c7c7c7' }}>
                                    125145462352656
                                </Box>
                                <Box textAlign='center' sx={{ width: '100%' }}>
                                    <Button size='large' color='success' variant='contained' sx={{ width: '100%', color: 'white', height: '5rem' }}>Naviagte to my flight</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </Box >
    )
}
