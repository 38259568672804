import { Box, Typography, Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ThermostatIcon from '@mui/icons-material/Thermostat';


export default function ThankYouFeedback() {
    const navigate = useNavigate()

    return (
      <>
        <Box
          sx={{
            zIndex: 2,
            display: "flex",
            flexWrap: "wrap",
            alignContent: "space-between",
          }}
        >
          <Box
            sx={{ width: "100%", display: "flex", alignItems: "center" }}
            p={4}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h1">Wilyer</Typography>
            </Box>

            <Box sx={{ display: "flex" }}>
              <AccessTimeIcon />
              <Typography variant="body2" sx={{ marginRight: 2 }}>
                09:30
              </Typography>

              <DateRangeIcon />
              <Typography variant="body2" sx={{ marginRight: 2 }}>
                23 Jun, 2022
              </Typography>

              <ThermostatIcon />
              <Typography variant="body2" sx={{ marginRight: 2 }}>
                34 C
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            minHeight: "100vh",
            minWidth: "100%",
            flexDirection: "column",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            top: 0,
          }}
        >
          <Typography variant="h2" sx={{ marginBottom: 4 }}>
            Thank you for <br />
            your feedback
          </Typography>
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              navigate("/main-nav");
            }}
          >
            Go to home
          </Button>
        </Box>
      </>
    );
}
