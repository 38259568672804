import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SearchIcon from '@mui/icons-material/Search';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import { Box, Fab, IconButton, MenuItem, TextField, Typography, Stack } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { withStyles } from '@mui/styles';
import React, { useState } from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from "react-router-dom";
import LanguageSelector from '../MainNav/LanguageSelector';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Offline, Online } from "react-detect-offline"
import { formatDate } from '../../utils/formatTime';
import { useEffect } from 'react';


export default function HelpDeskHeader() {
    const naviagte = useNavigate()
    const [time, settime] = useState('')
    const [currentDate, setcurrentDate] = useState('')
    let [airportName, setairportName] = useState('')

    const loadDateTimeInUI = () => {
        var date = new Date();
        settime(date.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' }).toString())
        setcurrentDate(formatDate(date))
    }

    useEffect(() => {
        try {
            setairportName(JSON.parse(localStorage.getItem('airport', '')).name)
        } catch (e) {
            console.log(e)
        }

        loadDateTimeInUI()

        const interval = setInterval(loadDateTimeInUI(), 2000)

        return () => {
            clearInterval(interval);
        }
    }, [])


    return (
      <Box
        sx={{
          position: "fixed",
          top: 0,
          minWidth: "100%",
        }}
      >
        <Box
          sx={{
            zIndex: 2,
            height: "110px",
            display: "flex",
            flexWrap: "wrap",
            alignContent: "space-between",
          }}
        >
          <Box
            sx={{ width: "100%", display: "flex", alignItems: "center" }}
            p={4}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h1">Wilyer</Typography>
            </Box>

            <Box sx={{ display: "flex" }}>
              <AccessTimeIcon />
              <Typography
                variant="body2"
                sx={{ marginRight: 2, marginLeft: 1 }}
              >
                {time}
              </Typography>

              <DateRangeIcon />
              <Typography
                variant="body2"
                sx={{ marginRight: 2, marginLeft: 1 }}
              >
                {currentDate}
              </Typography>

              <Offline>
                <Stack direction="row">
                  <FiberManualRecordIcon color="error" />
                  <Typography
                    variant="body2"
                    sx={{ marginRight: 2, marginLeft: 1 }}
                  >
                    Offline
                  </Typography>
                </Stack>
              </Offline>
              <Online>
                <Stack direction="row">
                  <FiberManualRecordIcon color="success" />
                  <Typography
                    variant="body2"
                    sx={{ marginRight: 2, marginLeft: 1 }}
                  >
                    Online
                  </Typography>
                </Stack>
              </Online>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }} p={4}>
          <Box mr={2}>
            <Fab
              color="primary"
              sx={{ borderRadius: 2, marginRight: 1 }}
              onClick={() => {
                naviagte(-1);
              }}
            >
              <ArrowBackIosNewIcon />
            </Fab>

            <Fab
              color="primary"
              sx={{ borderRadius: 2, marginRight: 1 }}
              onClick={() => {
                naviagte("/main-nav");
              }}
            >
              <HomeIcon />
            </Fab>
          </Box>

          <Box sx={{ flexGrow: 1 }}>
            <Typography>Welcome to</Typography>
            <Typography variant="h3">{airportName} Airport</Typography>
          </Box>

          <Box pt={2}>
            <LanguageSelector />
          </Box>
        </Box>
      </Box>
    );
}
