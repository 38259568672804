import React, { useEffect } from 'react'
import { Box, Grid, Typography, Card, Container, Stack } from '@mui/material'
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { Avatar } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import { useNavigate } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { useDispatch, useSelector } from 'react-redux';
import { selectFaqs, loadFAQs } from '../redux/slices/faqSlice';
import { useSelect } from '@mui/base';
import { Button } from '@mui/material';
import { useRef } from 'react';
import { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionActions } from "@mui/material";
import { selectGuidelines, loadGuidelines } from '../redux/slices/guidelineSlice';
import ChatbotHeader from '../components/Chatbot/ChatbotHeader';


export default function Guidelines() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const guidelines = useSelector(selectGuidelines)

    useEffect(() => {
        dispatch(loadGuidelines())
    }, [])

    return (
        <>
            <ChatbotHeader />

            <Box pl={3} pt={3}>
                <Typography variant='h2' color='white'>Guidelines</Typography>
            </Box>

            <Box p={2} sx={{ maxWidth: '700px', overflow: 'hidden' }}>
                <Typography color='white' dangerouslySetInnerHTML={{ __html: guidelines.text }} />
            </Box>
        </>
    )
}
