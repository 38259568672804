import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendServerBaseURL } from '../../utils/backendServerBaseURL';

const initialState = {
  guidelines: ''
};

export const loadGuidelines = createAsyncThunk(
  'guideline/loadGuidelines',
  async (_, thunkAPI) => {
    const terminalData = JSON.parse(localStorage.getItem("terminal", ""))

    let config = {
      params: {
        id: terminalData._id
      }
    }

    let response = await axios.get(`${backendServerBaseURL}/kiosk/guideline/read`, config);

    if (response.status === 200) {
      thunkAPI.dispatch(updateGuidelines(response.data.payload[0]))
    }
  }
);

export const guidelineSlice = createSlice({
  name: 'guideline',
  initialState,
  reducers: {
    updateGuidelines: (state, action) => {
      state.guidelines = action.payload;
    },
  }
})

export const {
  updateGuidelines,
} = guidelineSlice.actions;

export const selectGuidelines = (state) => state.guideline.guidelines;


export default guidelineSlice.reducer;
