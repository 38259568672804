import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/MainNav/Header';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { loadFAQs, selectFaqs, updateSelectedFAQ } from '../../redux/slices/faqSlice';
import { staticBaseURL } from '../../utils/backendServerBaseURL';
import { addLog } from '../../redux/slices/sessionSlice';

export default function FAQsQuestions() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()

    const faqs = useSelector(selectFaqs)

    useEffect(() => {
        dispatch(loadFAQs({ categoryId: params.categoryId }))
    }, [])


    return (
        <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
            <Header />

            <Box sx={{ minHeight: '100%', backgroundColor: '#ffffff96', flexGrow: 1 }}>
                <Box p={3}>
                    <Container>
                        <Box pt={3} pb={3}>
                            <Typography variant='h3' color='black'>{params.categoryName}</Typography>
                        </Box>


                        <Grid container spacing={1}>
                            {
                                faqs.map(faq => {
                                    return <Grid
                                        item
                                        xs={12}
                                        onClick={() => {
                                            dispatch(addLog(`Opened FAQ question - ${faq.question}`));
                                            dispatch(updateSelectedFAQ(faq));
                                            navigate(`/faqs-answer`)
                                        }}>
                                        <Box p={2} sx={{ backgroundColor: '#892C86' }}>
                                            <Typography variant='h4' textAlign={'center'}>{faq.question}</Typography>
                                        </Box>
                                    </Grid>
                                })
                            }
                        </Grid>

                    </Container>
                </Box>
            </Box>


        </Box>
    )
}
