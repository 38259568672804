import Peer from "simple-peer";

export class Peerlib {
  constructor(stream) {
    this.peer = new window.SimplePeer({
      initiator: true,
      trickle: false,
      stream: stream,
      config: {
        iceServers: [
          { urls: "stun:stun.pocsample.in:5349" },
          {
            urls: "turn:turn.pocsample.in:5349",
            username: "root",
            credential: "9876543210",
          },
        ],
      },
    });
  }

  onSignal(callback) {
    this.peer.on("signal", (data) => callback(data));
    return;
  }

  onStream(callback) {
    this.peer.on("stream", (stream) => callback(stream));
    return;
  }

  onCallAccepted(callback) {
    this.peer.on("callAccepted", (signal) => callback(signal));
    return;
  }
}
