import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { submitLogs } from "../redux/slices/sessionSlice";

export default function Home({ socket }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [airport, setAirport] = React.useState({});

  useEffect(() => {
    dispatch(submitLogs());

    try {
      socket.emit("kioskDBID", {
        dbId: JSON.parse(localStorage.getItem("kiosk", ""))._id,
      });
    } catch (e) {
      console.log(e);
    }

    const airport = localStorage.getItem("airport", "{}");
    if (airport) {
      setAirport(JSON.parse(airport));
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          zIndex: 2,
          height: "100vh",
          display: "flex",
          flexWrap: "wrap",
          alignContent: "space-between",
        }}
        onClick={() => {
          navigate("main-nav");
        }}
      >
        <Box sx={{ width: "100%" }} p={4}>
          <Typography variant="h1" textAlign={"right"}>
            Wilyer
          </Typography>
        </Box>

        <Box p={10} sx={{ width: "90%" }}>
          <Typography sx={{ marginBottom: 1, fontSize: 80 }}>
            Welcome to {airport.name}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ marginBottom: 1, fontSize: 30, marginRight: 2 }}>
              Touch the screen to Get Started
            </Typography>
            <Avatar alt="touch icon" src="/static/touch.svg" />
          </Box>
        </Box>
      </Box>
    </>
  );
}
