import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  Container,
  Stack,
  MenuItem,
  TextField,
} from "@mui/material";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import { Avatar } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import { useNavigate, useParams } from "react-router-dom";
import { withStyles } from "@mui/styles";
import { addLog } from "../../redux/slices/sessionSlice";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/MainNav/Header";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import FlatwareIcon from "@mui/icons-material/Flatware";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import ChatIcon from "@mui/icons-material/Chat";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  loadFlights,
  selectGates,
  selectFlights,
} from "../../redux/slices/DepartureAndArrival/gatesSlice";

export default function Gates() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const gates = useSelector(selectGates);
  const flights = useSelector(selectFlights);
  const params = useParams();

  useEffect(() => {
    dispatch(loadFlights({ direction: params.direction }));
  }, []);

  return (
    <Box sx={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <Header />

      <Box
        sx={{ minHeight: "100%", backgroundColor: "#ffffff96", flexGrow: 1 }}
      >
        <Box p={3}>
          <Container>
            <Box pt={3} pb={3}>
              <Typography variant="h3" color="black">
                Gates
              </Typography>
            </Box>

            <Grid container spacing={2}>
              {gates.map((gate) => {
                return (
                  <Grid item xs={4}>
                    <Box
                      p={2}
                      sx={{
                        backgroundColor: "#1883C6",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: 1,
                      }}
                      onClick={() => {
                        navigate(`/gates/${params.direction}/${gate}/flights`);
                      }}
                    >
                      <Box
                        p={2}
                        mr={1}
                        sx={{ backgroundColor: "white", color: "#1883C6" }}
                      >
                        {gate}
                      </Box>
                      <Box p={1}>
                        <Typography variant="body1">Gate {gate}</Typography>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Container>
        </Box>
      </Box>
    </Box>
  );
}
