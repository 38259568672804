import mock from "./mock";

mock.onGet("/v3/airports/:airportCode").reply((config) => {
  console.log(config);
  // console.log(airportCode)
  // console.log(direction)

  try {
    const response = {
      success: true,
      airports: [
        {
          type: "LARGE_AIRPORT",
          elevation: 8,
          continent: "NA",
          countryCode: "US",
          region: "US-FL",
          name: "Miami International Airport",
          iataCode: "MIA",
          icaoCode: "KMIA",
          city: "Miami",
          latitude: 25.79319953918457,
          longitude: -80.29060363769531,
          country: "United States",
          timezone: "America/Kentucky/Monticello",
        },
      ],
    };

    return [
      200,
      {
        data: response,
      },
    ];
  } catch (error) {
    console.error(error);
    return [500, { message: "Internal server error" }];
  }
});
