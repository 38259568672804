import mock from "./mock";

mock.onGet("/v1/airlines").reply((config) => {
  console.log(config);
  // console.log(airportCode)
  // console.log(direction)

  try {
    const response = {
      success: true,
      airlines: [
        {
          name: "IzAvia",
          iataCode: "KL",
          country: "Russia",
          svcType: "PASSENGER",
        },
        {
          name: "GoJet Airlines",
          iataCode: "G7",
          icaoCode: "GJS",
          alias: "GATEWAY",
          country: "United States",
          svcType: "PASSENGER",
        },
        {
          name: "Gulf Air Bahrain",
          iataCode: "GF",
          icaoCode: "GBA",
          alias: "GULF BAHRAIN",
          country: "Bahrain",
          svcType: "PASSENGER",
        },
        {
          name: "Vueling Airlines",
          iataCode: "VY",
          icaoCode: "VLG",
          alias: "VUELING",
          country: "Spain",
          svcType: "PASSENGER",
        },
        {
          name: "EgyptAir Express",
          icaoCode: "MSE",
          alias: "EGYPTAIR EXPRESS",
          country: "Egypt",
          svcType: "PASSENGER",
        },
        {
          name: "American Eagle Airlines",
          iataCode: "MQ",
          icaoCode: "EGF",
          alias: "EAGLE FLIGHT",
          country: "United States",
          svcType: "PASSENGER",
          parentAirline: "AA",
        },
        {
          name: "Maersk",
          iataCode: "DM",
          country: "Denmark",
          svcType: "PASSENGER",
        },
      ],
    };

    return [
      200,
      {
        data: response,
      },
    ];
  } catch (error) {
    console.error(error);
    return [500, { message: "Internal server error" }];
  }
});
