import { Box, Container, Grid, Typography, TextField, Button, IconButton } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/MainNav/Header';

import Keyboard from 'react-simple-keyboard';
import '../../onScreenKeyboard.css';

import { useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';

export default function ScanQRCode() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [flightNumber, setflightNumber] = useState('')
    const [showKeyboard, setshowKeyboard] = useState(false)

    const onChange = (input) => {
        console.log("Input changed", input);
        setflightNumber(input)
    }

    const onKeyPress = (button) => {
        console.log("Button pressed", button);
    }

    return (
        <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
            <Header />

            <Box sx={{ minHeight: '100%', backgroundColor: '#ffffff96', flexGrow: 1 }}>
                <Box p={3}>
                    <Container>
                        <Box pt={3} pb={3}>
                            <Typography variant='h3' color='black'>Scan QR Code</Typography>
                        </Box>


                        <Grid container spacing={2}>
                            <Box p={2} m={2} sx={{ backgroundColor: '#6e6e6e', opacity: 0.8, width: '100%' }}>
                                <Typography variant='h4' color='black' sx={{ marginBottom: 2 }}>Enter flight number manually</Typography>

                                <TextField
                                    label="Flight number"
                                    value={flightNumber}
                                    onFocus={() => { setshowKeyboard(true) }}
                                    placeholder='Flight number'
                                    variant="outlined"
                                    onChange={(e) => { setflightNumber(e.target.value) }}
                                    sx={{ backgroundColor: 'white', width: '100%', input: { color: '#121212' } }}
                                />

                                <Button variant='contained' sx={{ marginTop: 2, width: '100%' }} onClick={() => { setshowKeyboard(false) }}>Submit</Button>
                            </Box>
                        </Grid>
                    </Container>

                    {
                        showKeyboard && <Box sx={{ position: 'fixed', bottom: 0, left: 0, backgroundColor: 'white', minWidth: '100%', borderTopRightRadius: 20, borderTopLeftRadius: 20 }}>
                            <Box sx={{ minWidth: '670px', padding: 5 }}>
                                <Box textAlign='right'>
                                    <IconButton onClick={() => { setshowKeyboard(false) }}>
                                        <CancelIcon sx={{ color: '#121212', fontSize: '2rem', position: 'relative', top: -10 }} />
                                    </IconButton>
                                </Box>

                                <Keyboard
                                    style={{ color: 'black' }}
                                    onChange={input =>
                                        onChange(input)}
                                    onKeyPress={button =>
                                        onKeyPress(button)}
                                />

                            </Box>
                        </Box>
                    }



                </Box>
            </Box>
        </Box>
    )
}
