import React, { useState } from 'react'
import { Box, Grid, Typography, Card, Container, Stack, MenuItem, TextField } from '@mui/material'
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { Avatar } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import { useNavigate } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { addLog } from '../../redux/slices/sessionSlice';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../components/MainNav/Header';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import FlatwareIcon from '@mui/icons-material/Flatware';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import ChatIcon from '@mui/icons-material/Chat';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useParams } from 'react-router-dom';
import { loadAllFloors, selectFloors } from '../../redux/slices/mapsSlice';
import { useEffect } from 'react';
import { staticBaseURL } from '../../utils/backendServerBaseURL';
import { updateSelectedPOI } from '../../redux/slices/dineSlice';


export default function DineOptions() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()

    const floors = useSelector(selectFloors)
    const [pois, setpois] = useState([])
    useEffect(() => {
        dispatch(loadAllFloors())
    }, [])

    useEffect(() => {
        if (floors.length !== 0) {
            let arr = []
            floors.map(floor => {
                floor.pois.map(poi => {
                    if (Object.keys(poi).includes('category')) {
                        if (poi.category === params.name && poi.type === 'Dine') {
                            arr.push(poi)
                        }
                    }
                })
            })

            setpois(arr)
        }
    }, [floors])

    return (
        <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
            <Header />

            <Box sx={{ minHeight: '100%', backgroundColor: '#ffffff96', flexGrow: 1 }}>
                <Box p={3}>
                    <Container>
                        <Box pt={3} pb={3}>
                            <Typography variant='h3' color='black'>{params.name}</Typography>
                        </Box>

                        <Grid container spacing={2}>
                            {pois.map(poi => {
                                return <Grid item xs={4} onClick={() => { dispatch(updateSelectedPOI(poi)); navigate(`/dine-map`) }}>
                                    <Box
                                        component="img"
                                        sx={{
                                            height: '8rem',
                                            maxWidth: '100%',
                                            minWidth: '100%',
                                            backgroundColor: 'white'
                                        }}
                                        alt="Flights"
                                        src={poi?.imageURL != null ? staticBaseURL + "/" + poi?.imageURL : '/static/dine/restaurents.png'}
                                    >
                                    </Box>

                                    <Box p={2} sx={{ position: 'relative', top: -25, backgroundColor: 'black', opacity: 0.7, minWidth: '100%' }}>
                                        <Typography variant='h4' textAlign={'center'}>{poi.name}</Typography>
                                    </Box>
                                </Grid>
                            })}

                        </Grid>
                    </Container>
                </Box>
            </Box>
        </Box >
    )
}
