import mock from './mock';

mock.onGet('/v1/current/:location?temperatureScale=C').reply((config) => {
    console.log(config)
    // console.log(airportCode)
    // console.log(direction)

    try {
        const response = {
            "temperatureScale": "F",
            "lengthUnit": "M",
            "currentWeather": {
                "location": "MIA",
                "phrase": "Mostly cloudy",
                "temperature": "82",
                "heatIndex": "83",
                "windChill": "82",
                "relativeHumidity": "48",
                "relativeHumidityUnits": "%",
                "windDirection": "SE",
                "windSpeed": "13",
                "pressure": "30.06",
                "pressureUnits": "in/Hg",
                "visibility": "10",
                "feelsLikeTemperature": "81",
                "timeStamp": "2020-05-08T18:49:01Z",
                "icon": "06"
            }
        }

        return [200, {
            data: response
        }];
    } catch (error) {
        console.error(error);
        return [500, { message: 'Internal server error' }];
    }
});

