import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { backendServerBaseURL } from '../../utils/backendServerBaseURL';

const initialState = {
  weather: null,
};

export const loadWeather = createAsyncThunk(
  'weather/loadWeather',
  async (_, thunkAPI) => {
    let config = {
      params: {

      },
      headers: {
        'X-apiKey': 'AAAAAAAAAAAAAAAAAAAAAAAAAAA'
      }
    }

    const airportCode = JSON.parse(localStorage.getItem('airport', ''))['ICAO']

    let response = await axios.get(`/v3/airports/:airportCode`, config);

    if (response.status === 200) {
      const country = response.data.data.airports[0].country

      let weatherResponse = await axios.get(`/v1/current/:location?temperatureScale=C`, config);

      thunkAPI.dispatch(updateWeather(weatherResponse.data.data.currentWeather))
    }
  }
);


export const weatherSlice = createSlice({
  name: 'weather',
  initialState,
  reducers: {
    updateWeather: (state, action) => {
      state.weather = action.payload;
    },
  }
})

export const {
  updateWeather,
} = weatherSlice.actions;


export const selectWeather = (state) => state.weather.weather;


export default weatherSlice.reducer;
